export const formatPrice = (num: number): string => `$${(num || 0).toLocaleString('en-US')}`
export const formatPriceInK = (num: number): string => `$${Number(num / 1000).toLocaleString('en-US')}K`
export const formatNumber = (num: number): string => new Intl.NumberFormat('en-US').format(num)
export const formatNumberInK = (num: number): string => `${new Intl.NumberFormat('en-US').format(num / 1000)}K`
export const formatDate = (date: string | number): string | null => date ? new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'short', day: 'numeric' }).format(new Date(date)) : null

export function abbreviateNumber(num: number) {
  const suffixes = ['', 'k', 'm']
  let suffixNum = 0

  while (num >= 1000) {
    num = num / 1000
    suffixNum++
  }

  return Math.round(num) + suffixes[suffixNum]
}

<script setup lang="ts">
/**
 * Floating tooltip container - shared styles between UiPopover component and Geo-location tooltip
 */

const props = defineProps<{
  class?: any
}>()
</script>

<template>
  <div
    :class="cn(
      'z-10 mx-2 mb-px flex w-max max-w-[220px] select-none items-start gap-2 rounded-lg bg-black px-4 py-3 text-sm font-semibold text-white drop-shadow-xxs will-change-[transform,opacity] data-[state=open]:data-[side=bottom]:animate-slideDownAndFade data-[state=open]:data-[side=left]:animate-slideLeftAndFade data-[state=open]:data-[side=right]:animate-slideRightAndFade data-[state=open]:data-[side=top]:animate-slideUpAndFade',
      props.class,
    )"
  >
    <slot />
  </div>
</template>
